<template>
  <div>
    <v-card
      v-if="!hasError"
      flat
      color="transparent"
      :class="theme.global.name.value === 'dark' ? 'entry--dark' : undefined"
      class="carbon-ad"
      height="133"
      width="362"
      style="padding-bottom: 10px"
    >
      <v-card-text>
        <div ref="carbonads"></div>
      </v-card-text>
    </v-card>

    <v-card v-else height="133" width="362" />
  </div>
</template>

<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

const props = defineProps({
  scriptId: {
    type: String,
    default: '_carbonads_js',
  },
})

const hasError = ref(false)
const carbonads = ref(null)

onMounted(() => {
  const { ssrContext } = useNuxtApp()
  // Do nothing on ssr
  if (ssrContext) return
  if (typeof document === 'undefined' || !carbonads.value) return
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = props.scriptId
  script.src =
    '//cdn.carbonads.com/carbon.js?serve=CE7I4K7L&placement=awesometechstackcom'
  script.onerror = () => (hasError.value = true)
  carbonads.value.appendChild(script)
})
</script>

<style lang="sass">
.carbon-ad
  opacity: 1
  #carbonads_1,
  #carbonads_2
    display: none
  &--is-active
    opacity: 0
  .carbon-wrap
    display: flex
  .carbon-text,
  .carbon-poweredby
    max-width: 200px
    padding: 0 0 0 16px
    text-decoration: none
  .carbon-img img
    border-radius: 4px 0 0 4px
  .carbon-text
    color: #333
    font-size: 0.875rem
  .carbon-poweredby
    color: rgba(0, 0, 0, .56)
    font-size: 0.75rem
    position: absolute
    right: 16px
    bottom: 16px
  &.entry--dark
    .carbon-poweredby,
    .carbon-text
      color: #FFF
  .v-list-item__subtitle
    text-overflow: initial
    white-space: initial
</style>
