<template>
  <div id="tech-stack-analysis">
    <v-data-table
      v-if="renderComponent"
      :loading="loading"
      disable-pagination
      item-key="title"
      :group-by="groupBy"
      :headers="headers"
      :items="props.items"
      hide-default-footer
      :mobile-breakpoint="-1"
      :custom-sort="customSort"
      :items-per-page="-1"
    >
      <!-- Top slot -->
      <template v-slot:bottom></template>
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center pa-4">
          <h3>
            {{
              `${props.items.length > 0 ? props.items.length : ''} ${$t(
                'DetectedTechnologiesTable.title'
              )}`
            }}
          </h3>
          <!--<v-spacer />
          <v-switch
            v-model="showCategory"
            inset
            class="mt-0"
            hide-details
            :label="$t('DetectedTechnologiesTable.showCategories')"
          ></v-switch>-->
        </div>
      </template>

      <!-- Header slots -->
      <template v-slot:header.technology>
        {{ $t('DetectedTechnologiesTable.headerTechnology') }}
        <v-icon size="x-small" @click="dialog1 = true">
          {{ mdiHelpCircleOutline }}
        </v-icon>
      </template>
      <template v-slot:header.version>
        {{ $t('DetectedTechnologiesTable.headerVersion') }}
      </template>
      <template v-slot:header.latestVersion>
        <div></div>
        {{ $t('DetectedTechnologiesTable.headerLatestVersion') }}
        <v-icon
          :icon="mdiHelpCircleOutline"
          size="x-small"
          @click="dialog2 = true"
        >
        </v-icon>
      </template>
      <template v-slot:header.status>
        {{ $t('DetectedTechnologiesTable.headerStatus') }}
      </template>

      <!-- Item slots -->
      <template v-slot:item.technology="{ item }">
        <tech-img-with-title
          :icon="item.icon"
          :title="item.title"
          :category-slug="item.categorySlug"
          :slug="item.slug"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <impact-icon
          v-if="item.latestVersion"
          :value="item.status.value"
          :text="item.status.text"
          @click="goTo('suggestions')"
        />
      </template>
      <template v-slot:item.version="{ item }">
        <impact-version :value="item.status.value" :text="item.version" />
      </template>
      <template v-slot:item.latestVersion="{ item }">
        {{ item.latestVersion }}
      </template>
    </v-data-table>

    <app-info-dialog
      v-model="dialog1"
      :title="$t('DetectedTechnologiesTable.dialog1Title')"
    >
      <template #text>
        {{ $t('DetectedTechnologiesTable.dialog1Text') }}
      </template>
      <template #action>
        <v-btn variant="text" :to="{ name: 'why' }">
          {{ $t('DetectedTechnologiesTable.dialog1Button') }}
        </v-btn>
      </template>
    </app-info-dialog>
    <app-info-dialog
      v-model="dialog2"
      :title="$t('DetectedTechnologiesTable.dialog2Title')"
    >
      <template #text>
        {{
          $t('DetectedTechnologiesTable.dialog2Text', {
            updatedTimeAgoText: props.updatedTimeAgoText,
          })
        }}
      </template>
    </app-info-dialog>
  </div>
</template>

<script setup>
import { mdiHelpCircleOutline } from '@mdi/js'
import { useStore } from '@/composables/store'
import { goTo } from '@/utils/util'

// Props
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  updatedTimeAgoText: {
    type: String,
    default: '',
  },
})

// State
const showCategory = ref(false)
const dialog1 = ref(false)
const dialog2 = ref(false)

// Initialize the store
const store = useStore()

// i18n
const { t } = useI18n()

const groupBy = ref([])
const renderComponent = ref(true)

// Computed
const headers = computed(() => [
  {
    title: t('DetectedTechnologiesTable.headerTechnology'),
    align: 'start',
    key: 'technology',
    sortable: false,
  },
  {
    title: t('DetectedTechnologiesTable.headerVersion'),
    align: 'left',
    key: 'version',
    sortable: false,
  },
  {
    title: t('DetectedTechnologiesTable.headerLatestVersion'),
    align: 'center',
    key: 'latestVersion',
    sortable: false,
  },
  {
    title: t('DetectedTechnologiesTable.headerStatus'),
    align: 'right',
    key: 'status',
    sortable: false,
  },
])

// Watchers
watch(dialog1, (val) => {
  if (val) {
    // Your analytics event call here
  }
})
watch(dialog2, (val) => {
  if (val) {
    // Your analytics event call here
  }
})

const test = ref(0)
watch(showCategory, (val) => {
  if (val) {
    groupBy.value = [{ key: 'catsString', order: 'asc' }]
  } else {
    groupBy.value = []
  }

  // needs to rerender else groups will not load
  renderComponent.value = false
  setTimeout(() => {
    renderComponent.value = true
  })
})

// Methods
const customSort = (
  items,
  sortBy,
  sortDesc,
  locale,
  customSorters,
  compareFn
) => {
  if (sortBy[1] === 'name') {
    items.sort((a, b) => {
      if (!sortDesc[0]) {
        return a[sortBy[1]]
          .toLowerCase()
          .localeCompare(b[sortBy[1]].toLowerCase())
      } else {
        return b[sortBy[1]]
          .toLowerCase()
          .localeCompare(a[sortBy[1]].toLowerCase())
      }
    })
  }
  return props.items
}
</script>
