<template>
  <span :class="`text-${getColor(props.value)}`">{{ text }} </span>
</template>

<script setup>
const props = defineProps({
  value: {
    type: String,
    default: 'none',
  },
  text: {
    type: String,
    default: 'none',
  },
})

const getColor = (val) => {
  switch (val) {
    case 'none':
      return ''
    case 'low':
      return 'info'
    case 'medium':
      return 'warning'
    case 'high':
      return 'error'
    default:
      return ''
  }
}
</script>