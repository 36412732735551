<template>
  <ul>
    <li v-for="(item, i) in items" :key="i">
      <div class="d-flex align-center">
        {{ $t(`LighthouseAuditsList.${item.title}`) }}
        <a
          style="text-decoration: none"
          :title="item.href"
          :href="item.href"
          :rel="item.rel"
          target="_blank"
        >
          <v-icon x-small class="ml-1"> {{ mdiOpenInNew }} </v-icon>
        </a>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { mdiOpenInNew } from '@mdi/js'
const items = [
  {
    title: 'performance',
    href: 'https://web.dev/lighthouse-performance/',
    rel: 'noreferrer',
  },
  {
    title: 'accessibility',
    href: 'https://web.dev/lighthouse-accessibility/',
    rel: 'noreferrer',
  },
  {
    title: 'bestPractices',
    href: 'https://web.dev/lighthouse-best-practices/',
    rel: 'noreferrer',
  },
  {
    title: 'seo',
    href: 'https://web.dev/lighthouse-seo/',
    rel: 'noreferrer',
  },
  {
    title: 'pwa',
    href: 'https://web.dev/lighthouse-pwa/',
    rel: 'noreferrer',
  },
]
</script>
