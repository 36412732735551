<template>
  <v-chip
    style="width: 100%"
    class="justify-center"
    :color="getColor(props.value)"
    :class="`text-${getColor(props.value)}`"
  >
    <span class="text-decoration-capitalize">{{ props.text }}</span>
  </v-chip>
</template>

<script setup>
const props = defineProps({
  value: {
    type: String,
    default: 'none',
  },
  text: {
    type: String,
    default: 'none',
  },
})

const getColor = (val) => {
  switch (val) {
    case 'none':
      return 'grey'
    case 'low':
      return 'info'
    case 'medium':
      return 'warning'
    case 'high':
      return 'error'
    default:
      return 'grey'
  }
}
</script>
