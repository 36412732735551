<template>
  <div v-if="props.text !== 'none'">
    <v-icon :color="getColor(props.value)">
      {{ mdiAlertCircleOutline }}
    </v-icon>
  </div>
</template>

<script setup>
import { mdiAlertCircleOutline } from '@mdi/js'

// Props definition
const props = defineProps({
  value: {
    type: String,
    default: 'none',
  },
  text: {
    type: String,
    default: '',
  },
})

// Methods
const getColor = (val) => {
  switch (val) {
    case 'none':
      return 'grey'
    case 'low':
      return 'info'
    case 'medium':
      return 'warning'
    case 'high':
      return 'error'
    default:
      return ''
  }
}
</script>