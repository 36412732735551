<template>
  <div id="suggestions">
    <v-data-table
      v-show="props.items.length > 0 || store.status === 'fetching'"
      :loading="props.loading"
      disable-pagination
      hide-default-footer
      :mobile-breakpoint="-1"
      :headers="headers"
      :items="props.items"
      :items-per-page="-1"
    >
      <template v-slot:bottom></template>
      <template v-slot:item.impact="{ item }">
        <impact-chip
          :value="item.impact?.value"
          :text="item.impact?.text"
        ></impact-chip>
      </template>

      <template v-slot:item.category="{ item }">
        {{ item.category }}
      </template>

      <template v-slot:item.title="{ item }">
        <div style="display: flex; align-items: center">
          <tech-img
            class="mr-2"
            :icon="item.item?.icon"
            :title="`${item.item?.title}: ${item.item?.version}`"
          />
          {{ item.item?.title }}: {{ item.item?.version }}
        </div>
      </template>

      <template v-slot:item.guide="{ item }">
        <a
          v-if="item.link"
          class="text-accent"
          :title="item.item?.link"
          rel="help"
          target="_blank"
          :href="item.item?.link"
        >
          {{ item.guide }}
        </a>
        <nuxt-link
          v-else
          :title="`${item.item?.title}`"
          :to="{
            name: 'technologies-category-slug',
            params: {
              category: item.item?.categorySlug,
              slug: item.item?.slug,
            },
          }"
        >
          {{ item.guide }}
        </nuxt-link>
      </template>
    </v-data-table>

    <v-divider
      v-show="props.items.length > 0 || store.status === 'fetching'"
    ></v-divider>

    <template
      v-if="store.requestByIdResult && store.requestByIdResult.lighthouse"
    >
      <v-banner
        v-if="
          getStatusColor(store.requestByIdResult.techScore).status === 'high' &&
          getStatusColor(store.requestByIdResult.lighthouse.score).status ===
            'high'
        "
        color="primary"
        :icon="mdiCreation"
      >
        <div>
          Congratulations! This website is truly awesome! Don't just take our
          word for it - check out our list of
          <nuxt-link
            class="text-primary"
            :to="{ name: 'insights-websites-awesome' }"
          >
            Awesome Websites
          </nuxt-link>
          to see how it stacks up against the competition. Keep up the amazing
          work!
        </div>
      </v-banner>
      <template v-else>
        <v-banner
          v-if="
            getStatusColor(store.requestByIdResult.techScore).status !== 'high'
          "
          :color="getStatusColor(store.requestByIdResult.techScore).hex"
          :icon="mdiInformation"
        >
          <div>
            Want to take your website's awesomeness to the next level? Try
            incorporating more state-of-the-art technologies into your tech
            stack. For inspiration, check out our list of
            <nuxt-link
              class="text-primary"
              :to="{ name: 'insights-technologies-awesome' }"
              >top 10 awesome technologies</nuxt-link
            >
            and see how they can help boost your score and overall awesomeness.
          </div>
        </v-banner>

        <v-banner
          v-if="
            getStatusColor(store.requestByIdResult.lighthouse.score).status !==
            'high'
          "
          :color="getStatusColor(store.requestByIdResult.lighthouse.score).hex"
          :icon="mdiLighthouse"
        >
          <div>
            Want to boost your website's ranking and overall quality? Focus on
            improving your site's core web vitals. For more information on how
            to do this, check out the resources available at
            <a
              class="text-accent"
              href="https://web.dev/vitals/"
              target="_blank"
              rel="help"
              >Web Vitals</a
            >
            and
            <a
              class="text-accent"
              href="https://github.com/GoogleChrome/lighthouse"
              target="_blank"
              rel="help"
              >Lighthouse</a
            >.
          </div>
        </v-banner>
      </template>
    </template>
  </div>
</template>

<script setup>
import { mdiLighthouse, mdiInformation, mdiCreation } from '@mdi/js'
import { useStore } from '@/composables/store'

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()

const headers = computed(() => [
  {
    title: 'Impact',
    align: 'center',
    key: 'impact',
    sortable: false,
  },
  {
    title: 'Technology Analysis',
    key: 'title',
    sortable: false,
  },
  { title: 'Category', key: 'category' },
  { title: 'Guide', key: 'guide', sortable: false },
])

const getStatusColor = (val) => {
  switch (store.getStatus({ score: val })) {
    case 'error':
      return {
        status: 'low',
        hex: '#ffd4ff',
        rgb: '255, 212, 255',
      }
    case 'warning':
      return {
        status: 'mid',
        hex: '#ff9de5',
        rgb: '255, 157, 229',
      }
    case 'success':
      return {
        status: 'high',
        hex: '#ff619d',
        rgb: '255, 97, 157',
      }
    default:
      return {
        hex: '#bdbdbd',
        rgb: '96, 93, 98',
      }
  }
}
</script>
