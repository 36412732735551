<template>
  <website-list id="similar-ranked" tag="h4" :disabled="props.disabled" :loading="props.loading"
    title="Similar ranked websites" :items="props.items" />
</template>

<script setup>
const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
