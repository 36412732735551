<template>
  <v-btn
    v-bind="$attrs"
    :loading="store.status === 'fetching'"
    :disabled="store.statusRequestById === 'fetching'"
    :title="$t('UpdateAnalysisBtn.title')"
    @click="updateAnalysis"
  >
    <v-icon start>{{ mdiRefresh }}</v-icon>
    {{ $t('UpdateAnalysisBtn.updateButton') }}
    <span v-if="url"> &nbsp;{{ url }}</span>
  </v-btn>
</template>

<script setup>
import { mdiRefresh } from '@mdi/js'
import { useStore } from '@/composables/store'

const store = useStore()

const searchOnMounted = ref(null)

defineProps({
  url: {
    type: String,
    default: null,
  },
})

watch(store.statusRequestById, (val) => {
  if (val === 'success') {
    searchOnMounted.value = store.search
  }
})

onMounted(() => {
  searchOnMounted.value = store.search
})

const updateAnalysis = () => {
  store.setSearch({ url: searchOnMounted.value })
  store.analyze({})
}
</script>
