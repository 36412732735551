<template>
  <div>
    <app-container>
      <AppBreadcrumbs class="mb-2" :items="breadcrumbs" />
      <v-row
        align="center"
        justify="space-between"
        class="d-flex text-truncate"
      >
        <v-col cols="12" sm="auto" class="flex-shrink-1 pt-0">
          <div class="d-flex align-center">
            <favicon-img :domain="slug" :size="24"></favicon-img>
            <h1 class="d-block text-truncate mx-2">
              <NuxtLink
                class="link"
                :href="`https://${slug}?ref=awesometechstack.com`"
                rel="nofollow"
              >
                {{ slug }}
              </NuxtLink>
            </h1>
          </div>
          <p
            v-if="store.requestByIdResult.title"
            class="d-block text-truncate mb-0 text-medium-emphasis"
          >
            {{ store.requestByIdResult.title }}
          </p>
        </v-col>
        <v-col cols="12" sm="auto" class="flex-grow-1" style="max-width: 570px">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-grow-1">
              <analyze-bar
                theme="light"
                variant="solo"
                single-line
              ></analyze-bar>
            </div>
            <div class="d-flex ml-4 flex-shrink-1">
              <update-analysis-btn
                size="large"
                height="48"
              ></update-analysis-btn>
            </div>
          </div>
          <div class="d-flex align-center mt-2 flex-grow-1 text-end">
            <p class="text-caption my-0 flex-grow-1">
              Last updated:
              <span v-if="store.requestByIdResult.updatedTimeAgoText">
                {{ store.requestByIdResult.updatedTimeAgoText }}
              </span>
              <span v-else> N/A </span>
            </p>
            <v-icon end size="small" @click="dialog1 = true">
              {{ mdiHelpCircleOutline }}
            </v-icon>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <v-row>
            <v-col
              v-if="
                !isFetching &&
                store.status === 'failure' &&
                store.analyzedStatusCode == 405 &&
                store.analyzedMsg === 'Blocked by robots.txt'
              "
            >
              <v-alert type="error" text>
                {{ $t('Alert405.blockedByRobotsTxt') }}
              </v-alert>
            </v-col>
            <v-col
              v-else-if="
                !isFetching &&
                store.status === 'failure' &&
                store.analyzedStatusCode == 405
              "
            >
              <v-alert type="error" text>
                {{ $t('Alert405.message') }}
              </v-alert>
            </v-col>
            <v-col
              v-else-if="
                !isFetching &&
                store.status === 'failure' &&
                store.analyzedStatusCode == 402
              "
              cols="12"
            >
              <alert-402></alert-402>
            </v-col>
            <v-col
              v-else-if="
                !isFetching &&
                store.status === 'failure' &&
                store.analyzedStatusCode == 403
              "
              cols="12"
            >
              <alert-403 type="error"></alert-403>
            </v-col>
            <v-col
              v-else-if="
                !isFetching &&
                store.status === 'failure' &&
                store.analyzedStatusCode == 401
              "
              cols="12"
            >
              <sign-up-alert></sign-up-alert>
            </v-col>
            <v-col
              v-else-if="!isFetching && store.status === 'failure'"
              cols="12"
            >
              <v-alert type="error" text>
                <div class="mb-2 mt-n1 title">Analysis Failed</div>
                <div>
                  We were unable to analyze
                  {{ store.analyzingUrl }}
                </div>

                <div>
                  <div class="my-4">
                    <ul>
                      <li>
                        Try to analyze using
                        <em>{{
                          store.analyzingUrlSwitchedProtocolWithoutUrl
                        }}</em>
                        protocol:
                        <nuxt-link
                          :to="{
                            name: 'analysis-website-slug',
                            params: {
                              slug: store.analyzingUrlWithoutProtocol,
                            },
                            query: {
                              protocol:
                                store.analyzingUrlSwitchedProtocolWithoutUrl,
                            },
                          }"
                        >
                          {{ store.analyzingUrlSwitchedProtocol }}
                        </nuxt-link>
                      </li>
                      <li>
                        Make sure your website is free of malware and does not
                        open new pages while loading
                      </li>
                      <li class="mb-1">
                        Make sure your website does not block
                        <strong>AwesomeTechStack</strong> in your robots.txt
                      </li>
                    </ul>
                  </div>
                  <div class="mt-2">
                    If you think you are seeing this error wrongly, please
                    <nuxt-link
                      :to="{
                        name: 'contact',
                        query: {
                          message: `${store.analyzingUrl} - ${store.analyzedStatusCode}`,
                        },
                      }"
                    >
                      report the error
                    </nuxt-link>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col v-if="!isFetching && store.status === 'accepted'" cols="12">
              <v-alert outlined type="info" text>
                Please wait, an analysis process of
                {{ store.analyzingUrl }} is already running.
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-card>
                <card-header
                  class="d-flex align-center"
                  title="Technology stack analysis"
                >
                </card-header>

                <v-divider></v-divider>

                <div>
                  <detected-technologies-table
                    :loading="store.statusRequestById === 'fetching'"
                    :items="store.requestByIdResult.techs"
                    :updated-time-ago-text="
                      store.requestByIdResult.updatedTimeAgoText
                    "
                  />
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <card-header title="Improvement suggestions"></card-header>
                <v-divider></v-divider>
                <improvements-table
                  :items="store.requestByIdResult.diagnostics"
                  :loading="store.statusRequestById === 'fetching'"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <v-row>
            <v-col cols="12">
              <v-card
                :loading="store.statusRequestById === 'fetching'"
                :disabled="isFetching"
                outlined
              >
                <card-header title="Awesomeness analysis"></card-header>
                <v-divider></v-divider>
                <div
                  class="pa-4 d-flex justify-center align-center text-center"
                >
                  <div>
                    <score-circular
                      class="text-h3"
                      :size="120"
                      :width="7"
                      :value="store.requestByIdResult.score"
                    />
                    <h3 class="pt-2 text-text-overline">Awesomeness</h3>
                  </div>
                </div>

                <div class="pa-4 text-left">
                  <score-linear :value="store.requestByIdResult.techScore" />
                  <div class="d-flex align-center mt-2">
                    <h4 class="text-text-overline">Technology</h4>
                    <v-icon end size="small" @click="dialog2 = true">
                      {{ mdiHelpCircleOutline }}
                    </v-icon>
                  </div>
                </div>

                <div class="pa-4 text-left">
                  <score-linear
                    :value="store.requestByIdResult.techUpToDatenessScore"
                  />
                  <div class="d-flex align-center mt-2">
                    <h4 class="text-text-overline">Version</h4>
                    <v-icon end size="small" @click="dialog4 = true">
                      {{ mdiHelpCircleOutline }}
                    </v-icon>
                  </div>
                </div>

                <div class="pa-4 text-left">
                  <score-linear
                    :value="
                      store.requestByIdResult.lighthouse
                        ? store.requestByIdResult.lighthouse.score
                        : -1
                    "
                  />
                  <div class="d-flex align-center mt-2">
                    <h4 class="text-text-overline">Quality</h4>
                    <v-icon end size="small" @click="dialog3 = true">
                      {{ mdiHelpCircleOutline }}
                    </v-icon>
                  </div>
                  <template v-if="store.requestByIdResult.updatedTimeAgoText">
                    <v-btn
                      variant="tonal"
                      class="mt-4"
                      block
                      @click="openQualityAnalysis"
                    >
                      <v-icon start>{{ mdiLighthouse }}</v-icon>
                      Generate quality report
                    </v-btn>
                  </template>
                </div>
                <v-card flat>
                  <div class="pa-4 text-left">
                    <div class="d-flex align-center">
                      <v-icon start small> {{ mdiChartLineVariant }} </v-icon>
                      <h4 class="text-text-overline">Awesomeness history</h4>
                    </div>
                    <LazyWebsiteScoreLineChartCss
                      :value="store.requestByIdResult.techScore"
                      :disabled="isFetching"
                    />
                  </div>
                  <p
                    v-if="store.requestByIdResult.createdTimeAgoText"
                    class="px-4 pb-4 text-caption"
                  >
                    First analysis:
                    {{ store.requestByIdResult.createdTimeAgoText }}
                  </p>
                </v-card>
                <v-divider></v-divider>
                <v-card-text>
                  <div
                    style="width: 100%"
                    class="text-caption d-flex justify-center overflow-hidden"
                  >
                    <score-legend />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              v-if="
                store.requestById &&
                Object.keys(store.requestById).length > 0 &&
                store.requestByIdResult.score &&
                (store.requestByIdResult.techUpToDatenessScore === undefined ||
                  store.requestByIdResult.techScore === undefined)
              "
              cols="12"
            >
              <v-alert type="info" text :icon="mdiHistory">
                We updated our algorithm since the last analysis. Click on
                update to get a fresh and accurate awesomeness score.

                <ul>
                  <li>
                    Seperated the version and the technology score to get a
                    better update experience
                  </li>
                </ul>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-card>
                <card-header
                  :icon="mdiTrendingNeutral"
                  title="Similar ranked websites"
                ></card-header>
                <v-card-text>
                  <p>
                    Some similar ranked websites based on the awesomeness of
                    {{ slug }}
                  </p>
                </v-card-text>
                <v-divider></v-divider>
                <similar-ranked-websites
                  :disabled="isFetching"
                  :items="store.similarRankedItems"
                  :loading="store.statusRequestFetchWebsiteStats === 'fetching'"
                />
              </v-card>
            </v-col>
            <v-col class="pa-0" cols="12">
              <ads-carbon class="pa-0" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <app-divider />

      <v-container>
        <sections-index-website-analyzer />
      </v-container>

      <app-divider />

      <sections-index-how-it-works />
    </app-container>

    <v-dialog
      persistent
      :model-value="store.status === 'fetching'"
      max-width="350"
    >
      <v-card>
        <v-card-title> Running analysis </v-card-title>
        <v-card-text>
          <v-progress-linear
            aria-label="Loading"
            color="primary"
            indeterminate
            rounded
          ></v-progress-linear>
          <div class="mt-2">{{ store.analyzingUrl }}</div>
        </v-card-text>

        <v-card-text>
          <div class="text-caption">
            <v-btn variant="plain" color="primary" @click="store.abortAnalyze">
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <app-info-dialog
      v-model="dialog1"
      title="Domain Information Update"
      text="Refresh/update website analysis by clicking the update button"
    />
    <app-info-dialog v-model="dialog2" title="Technology Score">
      <template #text>
        The score ranges between 0-100. After analyzing a website's tech stack,
        we rate the technology awesomeness. We corporate with community
        platforms and trend analyzers to get accurate insights into technology's
        usage, reputation, security, stability and performance. Based on these
        parameters/metrics we are including:
        <ul class="my-2">
          <li>Open source datasets</li>
          <li>
            Technology's reputation on community platforms like github etc.
          </li>
          <li>Up-to-dateness, pulse etc.</li>
          <li>Maintenance, known issues, contributions etc.</li>
        </ul>
        to calculate the technology score of your website.
      </template>
      <template #action>
        <v-btn variant="text" :to="{ name: 'why' }">
          Why AwesomeTechStack
        </v-btn>
      </template>
    </app-info-dialog>
    <app-info-dialog v-model="dialog3" title=" Quality Score">
      <template #text>
        The score ranges between 0-100. After analyzing a website's tech stack,
        we rate the website's quality awesomeness. To calculate a website's
        quality, we are using
        <a
          class="text-accent"
          href="https://github.com/GoogleChrome/lighthouse"
          target="_blank"
          rel="help"
        >
          Lighthouse
        </a>
        data insights for your website. These are based on:
        <lighthouse-audits-list class="my-2"></lighthouse-audits-list>
        These values combined are used to calculate the average quality score of
        your website. We collaborate with Lighthouse to build a unique
        configuration to assure a stable awesomeness score.
      </template>
      <template #action>
        <v-btn variant="text" :to="{ name: 'why' }">
          Why AwesomeTechStack
        </v-btn>
      </template>
    </app-info-dialog>
    <app-info-dialog v-model="dialog4" title="Version Score">
      <template #text>
        The score ranges between 0-100. After analyzing a website's tech stack,
        we rate the version awesomeness. The version score represents a score
        for up-to-dateness of your technology stack. Keep your tech stack
        updated to have a future proof cutting edge experience.
      </template>
      <template #action>
        <v-btn variant="text" :to="{ name: 'why' }">
          Why AwesomeTechStack
        </v-btn>
      </template>
    </app-info-dialog>
  </div>
</template>

<script setup>
import {
  mdiOpenInNew,
  mdiHelpCircleOutline,
  mdiChartLineVariant,
  mdiUpdate,
  mdiSearchWeb,
  mdiLighthouse,
  mdiTrendingNeutral,
} from '@mdi/js'
import { useStore } from '@/composables/store'

const store = useStore()
const { gtag } = useGtag()
const route = useRoute()
const router = useRouter()

const { slug } = route.params
const { protocol } = route.query

const nuxtApp = useNuxtApp()
const dateFormatFilter = nuxtApp.$dateFormatFilter

const dialog1 = ref(false)
const dialog2 = ref(false)
const dialog3 = ref(false)
const dialog4 = ref(false)

const { $auth } = nuxtApp

function getUrlFromProtocol(protocol) {
  const { slug } = route.params
  return protocol
    ? protocol === 'http:'
      ? `http://${slug}`
      : `https://${slug}`
    : slug
}

const url = getUrlFromProtocol(protocol)

await useAsyncData('init', async () => {
  if (store.initAnalyzeUrl !== url) {
    await store.initAnalyze({ url })
  }

  return url
})

const breadcrumbs = [
  {
    disabled: false,
    exact: true,
    title: 'Home',
    to: { name: 'index' },
  },
  {
    disabled: false,
    exact: true,
    title: 'Website Tech Stack Analyzer',
    to: { name: 'products-website-analyzer' },
  },
  {
    disabled: true,
    exact: true,
    title: store.requestByIdResult._id || slug,
    to: route.fullPath,
  },
]

onMounted(async () => {
  if (store.runAnalyzer) {
    store.analyze({})
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 300)
})

const title = computed(() => {
  return `${slug[0].toUpperCase()}${slug.substring(1)} - Tech Stack Analysis`
})

const lastModified = computed(() => {
  const { updatedAt = new Date() } = store.requestByIdResult
  return new Date(updatedAt)
})

const description = computed(() => {
  const { score = 0, _id, techs = [] } = store.requestByIdResult
  return `Detected ${techs.length} technologies on ${_id}, with an awesomeness score of ${score}/100. See full report of core web vitals and technology stack analysis of ${_id}.`
})

const isFetching = computed(() => {
  return store.status === 'fetching' || store.statusRequestById === 'fetching'
})

watch(
  () => route.query,
  async (val) => {
    if (val) {
      const url = getUrlFromProtocol(val.protocol)
      await store.initAnalyze({ url })
      store.analyze({ url })
    }
  }
)

watch(
  () => store.runAnalyzer,
  (val) => {
    if (val) {
      store.analyze({})
    }
  }
)

const openQualityAnalysis = () => {
  router.push({
    name: 'analysis-website-reports-lighthouse',
    query: {
      url: store.requestByIdResult._id,
    },
  })
}

// Watchers for dialogs:
watch(dialog1, (val) => {
  if (val) {
    gtag('event', 'help', {
      event_category: 'Dialog',
      event_label: 'last updated website',
      value: store.url,
    })
  }
})

watch(dialog2, (val) => {
  if (val) {
    gtag('event', 'help', {
      event_category: 'Dialog',
      event_label: 'analysis website technology',
      value: store.url,
    })
  }
})

watch(dialog3, (val) => {
  if (val) {
    gtag('event', 'help', {
      event_category: 'Dialog',
      event_label: 'analysis website quality',
      value: store.url,
    })
  }
})

watch(dialog4, (val) => {
  if (val) {
    gtag('event', 'help', {
      event_category: 'Dialog',
      event_label: 'analysis website version',
      value: store.url,
    })
  }
})

useSeoMeta({
  title: title.value,
  description: description.value,
  ogDescription: description.value,
  ogTitle: title.value,
  articleModifiedTime: lastModified.value,
})
</script>
