<template>
  <v-dialog v-model="props.value" eager :max-width="props.maxWidth">
    <v-card>
      <v-card-title style="word-break: break-word">{{
        props.title
      }}</v-card-title>
      <v-card-text>
        <template v-if="props.text">
          {{ text }}
        </template>

        <slot v-else name="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="action"></slot>
        <v-btn varian="text" @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])

// Props definition
const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: [String, Number],
    default: 500,
  },
})

const close = function () {
  emit('update:modelValue', false)
}
</script>
